<template>
  <modal-video-layout
    :show-chat='false'
    :session='session'>
    <div class='p-8 flex flex-col lg:flex-row justify-between gap-y-10 lg:gap-y-0 lg:gap-x-10'>
      <div class='flex-grow main-session-modal-content'>
        <session-description 
          :session='session' 
          :session-color='sessionColor' />
        <div class='mt-10 bg-gray-100 p-8 rounded-md'>
          <h2 class='text-lg lg:text-xl font-semibold'>Q&A 남기기는 2월 4일 하루 동안만 가능합니다.</h2>
          <ul class='text-sm lg:text-base list-disc list-inside mt-2'>
            <li class='mb-2'>강의에 대한 질문은 <span class='text-red-500 font-semibold'>각 강의 페이지 하단 Q&A남기기</span>에 남겨 주십시오.</li>
            <li>질문을 취합하여 일괄적으로 답변을 드립니다.</li>
          </ul>
        </div>
        <comments v-if='showComments'
          :comments='mainComments' 
          :show-message-when-not-logged-in='true'
          :session='session'
          class='mt-10' />
      </div>
    </div>
  </modal-video-layout>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ModalVideoLayout         from '@/components/cmccme/ModalVideoLayout.vue'
import SessionDescription       from '@/components/cmccme/SessionDescription.vue'
import Comments                 from '@/components/comments/Comments.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

export default {
  name: 'SessionModalContent',
  components: {
    ModalVideoLayout,
    SessionDescription,
    Comments,
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapState('comments', [
      'comments',
    ]),
    ...mapGetters('comments', [
      'mainComments',
    ]),
    showWatchedIndicator () {
      return this.session.me.myViewedStatus
    },
    sessionColor () {
      return '#545454'
    },
    textColor () {
      return `color: #${this.sessionColor}`
    },
    sameOrAfterVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSameOrAfter(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    showComments () {
      return this.sameOrAfterVideoScheduledStartDate
    },
  },
  methods: {
    ...mapActions('users', [
      'getMyProfile',
    ]),
    ...mapActions('comments', [
      'getComments',
      'updateCommentInstance',
    ]),
  },
  mounted () {
    if (this.hasValidToken) {
      this.updateCommentInstance({instanceType: 'Session', instanceId: this.session.id})
      this.getComments({instanceType: 'Session', instanceId: this.session.id})
    }
  },
}
</script>

<style lang='scss' scoped>

@media (min-width: 1024px) { 
  .main-session-modal-content {
    width: calc(80% - 320px);
    max-width: 72rem;
  }
}
</style>

